import { z } from 'zod';

import { JSONObjectSchema } from './json';

const NoneAuthConfigurationSchema = z.object({
  type: z.literal('none'),
});

const TokenExchangeSchema = z.record(z.string(), z.string());

const OIDCSettingsSchema = z
  .object({
    authority: z.string().min(1),
    client_id: z.string().min(1),
    redirect_uri: z.string().min(1),
  })
  .and(JSONObjectSchema); // add transform for client library
const OIDCAuthConfigurationSchema = z.object({
  type: z.literal('oidc'),
  settings: OIDCSettingsSchema,
  shouldRedirectToOriginUrl: z.boolean().optional(),
  storage: z.enum(['memory', 'localstorage', 'sessionstorage']).optional(),
  tokenExchange: TokenExchangeSchema.and(
    z.object({ client_id: z.string() }),
  ).optional(),
});

export const AuthConfigurationSchema = z.discriminatedUnion('type', [
  NoneAuthConfigurationSchema,
  OIDCAuthConfigurationSchema,
]);
export type AuthConfiguration = z.infer<typeof AuthConfigurationSchema>;
export type OIDCAuthConfiguration = z.infer<typeof OIDCAuthConfigurationSchema>;
export type NoneAuthConfiguration = z.infer<typeof NoneAuthConfigurationSchema>;
